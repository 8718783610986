import { copy } from "@/hooks/Copy";
import { useAppStore } from '@/store/app';

export default async () => {
  const appStore = useAppStore();
  const acc = (await appStore.getAccount()) || '';  // 获取账号
  const pass = (await appStore.getPassword()) || ''; // 获取密码
  const loginType = appStore.loginType || '';
  const token = appStore.token || '';

  // 获取当前路由的完整查询参数
  const currentQuery = window.location.search.slice(1)
  const rawAccountInfo = `loginType=${loginType}&acc=${acc}&pass=${pass}&token=${token}&${currentQuery}`;
  const accountInfo = btoa(rawAccountInfo);
  copy(accountInfo, false);
}
